<template>
    <div style="text-align: left;">
        <el-card shadow="never" style="margin-bottom: 25px;">
            <h3 class="title text-center">祝贺艾高家居荣登2020年衣柜十大品牌榜</h3>
            <div class="df-ai-c c-2 f14">
                <div><i class="el-icon-s-custom"></i>发布： <span>张小龙</span></div>
                <div class="m-l-10"><i class="el-icon-date"></i>日期： <span>2021-12-12 12：00</span></div>
                <div class="m-l-10"><i class="el-icon-view"></i>浏览： <span>1354</span></div>
            </div>
            <div class="m-t-15 f14">
                当今时代，随着全球跨地域文化交流的不断深入，厨房生活的理念发生了明显的变化，厨房观念的转变意味着人们生活品质和生活方式的变化，欧派橱柜十大品牌也随着变迁一直在升级。

        

目前，国内厨柜业领导者欧派“广州欧派厨柜企业有限公司”全面升级为“广东欧派集团有限公司”，同时，其亚洲规模第一的新生产基地也正式启用。另外，欧派橱柜十大品牌集团在国内一千多家专营店新装新形象，搭载着2011厨柜、衣柜新品，也一起亮相，新组建的欧派集团首亮“新厂、新店、新品”三柄利剑，全面布局旺季市场。

欧派升级后，将形成以欧派整体厨柜为旗舰，以欧派厨电、欧派衣柜、欧派建材为舰群的订制家居一体化产业群，企业的整体规模和战斗力将得到全面提升。

欧派橱柜十大品牌董事长姚良松称，欧派的中长期目标是用十五年左右的时间，建设成为国际著名的现代家居集团。到2011年，欧派计划集团产销额达到35-40亿;到2014年，成为国内一流的现代家居集团，集团产销额达到80-100亿元;到2024年，成为国际著名的现代家居集团。
            </div>
        </el-card>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";
import { enterpriseNewsAlbum, enterpriseNewsAlbumItem } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "Photo",
    data() {
        return {
            // 下面是验证的
            classifyFormRule: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                classify: [
                    { required: true, message: '请选择分类', trigger: 'blur' },
                ]
            },
            hasList: true,
            classifyDialogFormVisible: !true,
            list: [{ name: '家具分类1', id: '1' }, { name: '家具分类2', id: '2' }],
            activeName: '1',
            classifyForm: '',
            classify: '',
            name: '',
            value: '',
            tableData: [{
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-08',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-06',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-07',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }],
            multipleSelection: [],
            prlductData: {},
            enterpriseId: null,
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }
        this.getAllEnterpriseNewsAlbumsClient()
    },
    methods: {
        handleClick() {},
        submitClassifyForm() {},
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            // me.findAllFurnitureSpu();
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            // me.findAllFurnitureSpu();
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 查询企业的新闻册
        getAllEnterpriseNewsAlbumsClient() {
            enterpriseNewsAlbum.getAllEnterpriseNewsAlbumsClient({ enterpriseId: this.enterpriseId }).then(res => {

            })
        },
        addAttr: function() {
            const me = this;
            me.$prompt('请输入分类名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\S+$/,
                inputErrorMessage: '分类名称不正确'
            }).then(({ value }) => {
                enterpriseNewsAlbum.addEnterpriseNewsAlbum({ enterpriseId: this.enterpriseId, newsAlbumName: value }).then(res => {

                })
            });
        },
    }
}
</script>
<style scoped>
.my-header {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}

.items {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    align-content: flex-start;
    /*padding: 10px;*/
}

.item {
    /*flex: 1;*/
    max-width: 25%;
    margin: 10px;
    text-align: center;
    background: #F6F6F6;
    border: 1px solid #EAEAEA;
    cursor: pointer;
}
</style>